//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Search from "@/components/Search";
//  import translations from "./translation.json";
import translations from "@/assets/translations.json";

export default {
    name: "orderConfirm",
    components: { Search },
    data() {
        return {
            form: {
                user_coupon_id: "",
                address_id: "",
                integral: "",
                cart_id: "",
                delivery_time: "",
                buyer_remark: "",
                youhuima: "",
                songallcount: 0,
                songarr: [],
                charges: [],
                charges_us: [],
                songarrlite: {}
            },
            dialogVisible: false,
            addressForm: {
                contact: '',
                contact_number: '',
                address: '',
                house_number: '',
                is_default: true,
                province: '',
                city: '',
                district: '',
                longitude: '',
                latitude: '',
                address_id: '',
                region: []
            },
            couponList: [],
            settlement: null,
            currency: window.localStorage.getItem('currency'),
            uniacid: null,
            locale: window.localStorage.getItem('locale') || 'EN'
        };
    },
    created() {
        this.form.cart_id = this.$route.params.cart_id || ''
        this.getsettlement();
        const webinfo = JSON.parse(window.localStorage.getItem('webinfo') || '{}');
        this.uniacid = webinfo.uniacid;
    },

    methods: {
        changeCoupon() {
            this.getsettlement()
        },
        translateGoodsName(goodsName) {
            if (this.locale === 'EN') {
                return translations[goodsName] || goodsName; // Use translations from JSON
            }
            return goodsName; // Return original name if locale is not 'EN'
        },
        // 获取结算信息
        async getsettlement() {
            const res = await this.$http.get("/settlement", this.form);
            if (res.code !== 0) {
                this.$store.dispatch('global/handleMessage', { message: res.message });
            }
            this.settlement = res.data;
            this.form.songallcount = this.settlement.songallcount;
            this.couponList = this.settlement.coupon.list.map(item => {
                item.label = '满' + item.minimum + '减' + item.discount
                item.value = item.user_coupon_id
                return item
            })
            this.form.songarrlite = this.settlement.songarrlite
            this.form.charges = this.settlement.charges
            this.form.charges_us = this.settlement.charges_us
        },
        // 提交订单
        async formSubmit() {
            var ss = window.localStorage.getItem('webinfo');
            var ssarray = JSON.parse(ss)
            var uniacid = ssarray.uniacid
            let form = this.form
            for (let i in this.settlement) {
                form = { ...form, ...this.settlement[i].form }
            }
            let cartIdList = []
            this.settlement.goods.list.forEach(item => {
                cartIdList.push(item.cart_id)
            })
            form.cart_id = cartIdList.join(',')
            form.uniacid = uniacid
            form.order_currency = this.currency
            const res = await this.$http.post('/settlement/submit', form)
            if (res.code != 0) {
                this.$store.dispatch('global/handleMessage', { message: res.message });
            }
            this.$router.push({ name: 'User' })
        },
        // 提交
        async orderFormSubmit() {
            let form = this.addressForm
            form.is_default = Number(form.is_default)
            let region = form.region || []
            form.province = region.shift() || ''
            form.city = region.shift() || ''
            form.district = region.shift() || ''
            const api = '/user.address/' + ('add')
            const res = await this.$http.post(api, form)
            if (res.code == 0) {
                this.dialogVisible = false
                this.getsettlement()
            } else {
                this.$store.dispatch('global/handleMessage', { message: res.message });
            }
        }
    }
}
